import ErrorPage from "./components/ErrorPage";
import App from './App';
import InternalContentCreator from "./components/InternalContentCreator";
import Redirect from "./components/Redirect";
import NewsAdmin from "./components/pressReleases/NewsAdmin";
import {Navigate, RouteObject} from "react-router-dom";
import Report from "./components/pressReleases/Report";
import ImpactReport from "./components/pressReleases/ImpactReport";

const initialRoutes: RouteObject[] = [
    {
        path: "/",
        element: <Redirect url={"home.html"}/>,
        errorElement: <App component={<ErrorPage/>}/>,
    },
    {
        path: "/link-local",
        element: <Navigate to="/advertise" replace /> // 301 Redirect
    },
    {
        path: "/local-information.html",
        element: <Navigate to="/advertise" replace /> // 301 Redirect
    },
    {
        path: "/local-information",
        element: <Navigate to="/advertise" replace /> // 301 Redirect
    },
    {
        path: "/link-local.htm",
        element: <Navigate to="/advertise" replace /> // 301 Redirect
    },
    {
        path: "/link-local.html",
        element: <Navigate to="/advertise" replace /> // 301 Redirect
    },
    {
        path: "/local-information.htm",
        element: <Navigate to="/advertise" replace /> // 301 Redirect
    },
    {
        path: "/advertise",
        element: <Redirect url={"advertise.html"}/>,
        errorElement: <App component={<ErrorPage/>}/>,
    },
    {
        path: "/report",
        element: <App component={<Report/>}/>,
        errorElement: <App component={<ErrorPage/>}/>,
    },
    {
        path: "/impact-report",
        element: <App component={<ImpactReport/>}/>,
        errorElement: <App component={<ErrorPage/>}/>,
    },
    {
        path: "/I1qRlWdvvBN2Zg5AkS74dhHHqQgI3iiULpVasUCmsLl4EAt7YZ",
        element: <App component={<InternalContentCreator/>}/>,
        errorElement: <App component={<ErrorPage/>}/>,
    },
    {
        path: "/I1qRlWdvvBN2Zg5AkS74dhHHqQgI3iiULpVasUCmsLl4EAt7YZ/news-admin",
        element: <App component={<NewsAdmin/>}/>,
        errorElement: <App component={<ErrorPage/>}/>,
    },
]

export default initialRoutes;
