import React from 'react';
import '../styles/mainFooter.css';

const MainFooter= () => {
  return (
    <footer>
      <p>© Copyright 2025 Citybridge. All rights reserved.&nbsp;
        <a href="/terms-of-use.html">Terms of Use</a>
        &nbsp;and&nbsp;
        <a href="/privacy-policy.html">Privacy Policy</a></p>
    </footer>
  );
}

export default MainFooter;
